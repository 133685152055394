import { startOfDay } from "date-fns";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal, Radio, Search, SearchResultData, Form as SemanticForm } from "semantic-ui-react";

import { DATE_FORMATS_FNS, Form, FormInput } from "components";
import { useDealersLocations } from "hooks";
import { ExactStandaloneLicense } from "models";
import "modules/LocationLicenseOverview/components/StandaloneLicenseModal/StandaloneLicenseModal.scss";
import { useSelectedStandaloneLicenceCreate, useSelectedStandaloneLicenceDelete, useSelectedStandaloneLicenceUpdate } from "modules/LocationLicenseOverview/hooks";
import { INVOICING_OPTIONS, LOCATION_LICENSE_SUBPAGE, LicenseData, LicenseItem, StandaloneModalLicense } from "modules/LocationLicenseOverview/types";
import { filterLicenseData, findExactItemById, getNextMonthlyInvoicingDate } from "modules/LocationLicenseOverview/utils";
import { formatDate, toDate } from "util/dateHelperFunctions";
import { formatPrice, parsePriceFromInput } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

type StandaloneLicenseModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedLicense: LicenseData | null;
  items: LicenseItem[];
  selectedTab: LOCATION_LICENSE_SUBPAGE;
  isLoadingLicenses: boolean;
};

const defaultLicenseState: StandaloneModalLicense = {
  search: "",
  searchedItems: [],
  item: null,
  description: null,
  price: 0,
  quantity: 0,
  isSearchDropdownOpen: false
};

export const StandaloneLicenseModal = ({ onClose, isOpen, selectedLicense, items, selectedTab, isLoadingLicenses }: StandaloneLicenseModalProps) => {
  const t = useTranslation().t as ITranslation;
  const [selectedInvoicingOption, setSelectedInvoicingOption] = useState<INVOICING_OPTIONS | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [license, setLicense] = useState<StandaloneModalLicense>(defaultLicenseState);
  const [customInvoicingDate, setCustomInvoicingDate] = useState<Date | null>(null);
  const [standaloneLicenseID, setStandaloneLicenseID] = useState<number | null>(null);
  const [hasValidationError, setHasValidationError] = useState(false);

  const standaloneLicenceDeleteMutation = useSelectedStandaloneLicenceDelete();
  const standaloneLicenceCreateMutation = useSelectedStandaloneLicenceCreate();
  const standaloneLicenceUpdateMutation = useSelectedStandaloneLicenceUpdate();
  const { selectedLocation } = useDealersLocations();

  const closeModal = () => {
    setSelectedInvoicingOption(null);
    setLicense(defaultLicenseState);
    setCustomInvoicingDate(null);
    setStandaloneLicenseID(null);
    setIsDatePickerOpen(false);
    setHasValidationError(false);
    onClose();
  };

  const handleOpenModal = () => {
    if (!selectedLicense?.id) return;
    const item = findExactItemById(items, selectedLicense.exact_item_id);
    if (!item) return;

    setLicense(prevLicense => ({
      ...prevLicense,
      quantity: selectedLicense.quantity,
      item: item,
      description: selectedLicense.description || "",
      price: selectedLicense.price || item.StandardSalesPrice || 0,
      search: `${item.Code} - ${item.Description}`,
      isSearchDropdownOpen: false
    }));

    setStandaloneLicenseID(selectedLicense.id);
    if (selectedTab === LOCATION_LICENSE_SUBPAGE.MonthlyLicenses) return setSelectedInvoicingOption(INVOICING_OPTIONS.Monthly);

    if (selectedLicense.invoiceOn) setCustomInvoicingDate(selectedLicense.invoiceOn);
    setSelectedInvoicingOption(INVOICING_OPTIONS.OnceOnDate);
  };

  const handleSearch = (value: string) => {
    const filteredValues = filterLicenseData(items, value);

    setLicense(prevLicense => ({
      ...prevLicense,
      search: value,
      searchedItems: filteredValues,
      isSearchDropdownOpen: true
    }));
  };

  const handleItemClick = (data: SearchResultData) => {
    const item = data?.result;
    setLicense(prevLicense => ({
      ...prevLicense,
      item: item || null,
      price: item?.StandardSalesPrice || 0,
      search: item ? `${item.Code} - ${item.Description}` : "",
      searchedItems: [],
      isSearchDropdownOpen: false
    }));
  };

  const prepareSaveParams = () => {
    if (!license.item || !license.price || selectedInvoicingOption === null || !license.quantity || !selectedLocation) {
      setHasValidationError(true);
      return false;
    }

    setHasValidationError(false);
    const params: Record<string, Partial<ExactStandaloneLicense>> = {
      license: {
        dealer_location_id: selectedLocation.id,
        exact_item_id: license.item.ID,
        quantity: license.quantity,
        description: license.description || ""
      }
    };
    if (selectedInvoicingOption !== INVOICING_OPTIONS.Monthly)
      params.license.invoice_on = selectedInvoicingOption === INVOICING_OPTIONS.OnceOnDate ? customInvoicingDate : toDate(startOfDay(getNextMonthlyInvoicingDate()));
    if (license.price !== license.item.StandardSalesPrice) params.license.price = license.price;

    return params;
  };

  const handleQuantity = (value: string) => {
    setLicense(prevLicense => ({
      ...prevLicense,
      quantity: Number(value.replace(/[^0-9]/g, "")),
      isSearchDropdownOpen: false
    }));
  };

  const handleDescription = (value: string) => {
    setLicense(prevLicense => ({
      ...prevLicense,
      description: value || "",
      isSearchDropdownOpen: false
    }));
  };

  const handlePrice = (value: string) => {
    const parsedPrice = parsePriceFromInput(value);

    setLicense(prevLicense => ({
      ...prevLicense,
      price: parsedPrice,
      isSearchDropdownOpen: false
    }));
  };

  const handleCreate = async (params: Record<string, Partial<ExactStandaloneLicense>>) => {
    const updatedParams = { license: { ...params.license, created_on: new Date().toISOString() } };
    await standaloneLicenceCreateMutation.mutateAsync(updatedParams);
    closeModal();
  };

  const handleUpdate = async (params: Record<string, Partial<ExactStandaloneLicense>>) => {
    const updatedParams = { license: { ...params.license, id: standaloneLicenseID, updated_on: new Date().toISOString() } };
    await standaloneLicenceUpdateMutation.mutateAsync(updatedParams as Record<string, ExactStandaloneLicense>);
    closeModal();
  };

  const handleSave = () => {
    const params = prepareSaveParams();
    if (!params) return;
    return standaloneLicenseID ? handleUpdate(params) : handleCreate(params);
  };

  const handleChangeDate = (date: Date | null) => {
    if (!date) return;

    setCustomInvoicingDate(date);
    setSelectedInvoicingOption(INVOICING_OPTIONS.OnceOnDate);
    setIsDatePickerOpen(false);
  };

  return (
    <Modal open={isOpen} size="large" onClose={closeModal} onMount={handleOpenModal} closeOnDimmerClick={false} className="StandaloneLicenseModal">
      <Modal.Header>{t("v8_add_license_manually").message || "Add license manually"}</Modal.Header>
      <Form<ExactStandaloneLicense> onSubmit={handleSave} formProps={{ loading: isLoadingLicenses }}>
        <div className="content">
          <SemanticForm.Group>
            <SemanticForm.Field error={hasValidationError && !license.search} className="dropdown-search" width={7} required>
              <label className="big">{t("v8_license").message || "License"}</label>
              <div className="search">
                <Search
                  fluid={true}
                  placeholder={t("v8_search_for_license").message || "Search for license"}
                  icon="magnifying glass"
                  noResultsMessage={t("v8_no_results").message || "No results found"}
                  onSearchChange={(_e, { value }) => handleSearch(value || "")}
                  onResultSelect={(_e, data) => handleItemClick(data)}
                  resultRenderer={({ Code, Description }) => <p>{`${Code} - ${Description}`}</p>}
                  results={license.searchedItems}
                  value={license.search}
                  open={license.isSearchDropdownOpen}
                />
                <Button color="green" type="button" className="mt-14 rowButton" onClick={() => handleSearch(license.search)}>
                  {t("v8_search").message || "Search"}
                </Button>
              </div>
            </SemanticForm.Field>
            <SemanticForm.Field width={5}>
              <label>{t("v8_overwrite_name").message || "Overwrite name"}</label>
              <FormInput<ExactStandaloneLicense>
                inputProps={{ fluid: true, value: license.description, onChange: e => handleDescription(e.target.value) }}
                name="description"
              />
            </SemanticForm.Field>
            <SemanticForm.Field width={2} error={hasValidationError && !license.quantity} required>
              <label>{t("v8_amount").message || "Amount"}</label>
              <FormInput<ExactStandaloneLicense>
                inputProps={{ fluid: true, type: "number", value: license.quantity, onChange: e => handleQuantity(e.target.value) }}
                name="quantity"
              />
            </SemanticForm.Field>
            <SemanticForm.Field width={2} error={hasValidationError && !license.price} required>
              <label>{t("v8_unit_price").message || "Unit price"}</label>
              <FormInput<ExactStandaloneLicense>
                inputProps={{
                  fluid: true,
                  type: "text",
                  value: formatPrice(license.price),
                  onChange: e => handlePrice(e.target.value),
                  label: { basic: true, content: "€" },
                  labelPosition: "left"
                }}
                isPriceInput
                name="price"
              />
            </SemanticForm.Field>
          </SemanticForm.Group>
          <SemanticForm.Group>
            <SemanticForm.Field width={16} className="mt-20" error={hasValidationError && selectedInvoicingOption === null} required>
              <label>{t("v8_billing_options").message || "Billing options"}</label>
            </SemanticForm.Field>
          </SemanticForm.Group>
          <SemanticForm.Group>
            <SemanticForm.Field className="radio-button" width={16}>
              <label>{t("v8_monthly").message || "Monthly"}</label>
              <Radio
                name="billingOptions"
                value={INVOICING_OPTIONS.Monthly}
                checked={selectedInvoicingOption === INVOICING_OPTIONS.Monthly}
                onChange={() => setSelectedInvoicingOption(INVOICING_OPTIONS.Monthly)}
              />
            </SemanticForm.Field>
          </SemanticForm.Group>
          <SemanticForm.Group>
            <SemanticForm.Field className="radio-button" width={16}>
              <label>{`${t("v8_once_on_date").message || "Once, on date"}: ${customInvoicingDate ? formatDate(customInvoicingDate, DATE_FORMATS_FNS.dateMonthYear) : ""}`}</label>
              <ReactDatePicker
                selected={customInvoicingDate ? toDate(customInvoicingDate) : new Date()}
                onChange={handleChangeDate}
                showMonthDropdown
                showYearDropdown
                minDate={toDate(new Date())}
                dateFormat="dd-MM-yyyy"
                onClickOutside={() => setIsDatePickerOpen(false)}
                onCalendarClose={() => setIsDatePickerOpen(false)}
                onCalendarOpen={() => setIsDatePickerOpen(true)}
                withPortal
                inline={false}
                open={isDatePickerOpen}
                customInput={
                  <Radio
                    name="billingOptions"
                    value={INVOICING_OPTIONS.OnceOnDate}
                    checked={selectedInvoicingOption === INVOICING_OPTIONS.OnceOnDate}
                    onClick={() => setIsDatePickerOpen(true)}
                  />
                }
              />
            </SemanticForm.Field>
          </SemanticForm.Group>
          <SemanticForm.Group>
            <SemanticForm.Field className="radio-button" width={16}>
              <label>{t("v8_once_on_next_months_billing_date").message || "Once, on next month's billing date"}</label>
              <Radio
                name="billingOptions"
                value={INVOICING_OPTIONS.OnceInBillingCycle}
                checked={selectedInvoicingOption === INVOICING_OPTIONS.OnceInBillingCycle}
                onChange={() => setSelectedInvoicingOption(INVOICING_OPTIONS.OnceInBillingCycle)}
              />
            </SemanticForm.Field>
          </SemanticForm.Group>
        </div>
        <div className="actions">
          <div className="action-buttons">
            <Button color="light" onClick={closeModal}>
              {t("v8_cancel").message || "Cancel"}
              <Icon className="xmark" />
            </Button>
            <Button
              color="green"
              type="submit"
              disabled={standaloneLicenceDeleteMutation.isPending}
              loading={standaloneLicenceUpdateMutation.isPending || standaloneLicenceCreateMutation.isPending}
            >
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
