import { createColumnHelper } from "@tanstack/react-table";
import _startCase from "lodash/startCase";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";

import {
  CustomerCard,
  MODAL_TYPE,
  QuestionResultData,
  QuestionStatusIcon,
  ReactTable,
  TableDatePicker,
  TableDropdownSelector,
  TableQuestionStatusButton
} from "components";
import { useUser } from "hooks";
import { DealerLocation, SnoozeItem } from "models";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { MediaPreview, SnoozeButtonIcon } from "modules/LeadsDashboard/components";
import { useSnoozedLeadsTableFilterHandlers } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";
import { getFormattedDate, getLocationName } from "modules/LeadsDashboard/util";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

type SnoozedLeadsTableProps = {
  locations: DealerLocation[];
  setModalData: Dispatch<SetStateAction<InterventionData | QuestionResultData | null>>;
  snoozedData: SnoozeItem[];
  nbPages: number;
  nbItems: number;
};

export interface SnoozeItemWithHistory extends SnoozeItem {
  history: SnoozeItem[];
}

const PAGE_SIZE = 100;

export const SnoozedLeadsTable = ({ locations, setModalData, snoozedData, nbPages, nbItems }: SnoozedLeadsTableProps) => {
  const user = useUser();
  const t = useTranslation().t as ITranslation;

  const {
    handleOnTaskSelect,
    handleOnLeaseSelect,
    handlePaginationSelect,
    handleOnQuestionSelect,
    handleOnSnoozeStatusSelect,
    handleOnCustomerOkUserSelect,
    handleSnoozedDateRangeChange,
    handleQuestionResultStatusSelect,
    handleAppointmentDateRangeChange,
    handleUpdatedOnDateRangeChange,
    currentPage,
    snoozedFilters,
    dealerSnoozeTypeOptions,
    allSnoozeTypeOptions,
    selectedQuestions,
    selectedTaskOption,
    leaseCompanyOptions,
    snoozeStatusOptions,
    customerOkUserOptions,
    areAllDateFiltersReset,
    selectedQuestionStatuses,
    selectedSnoozeStatusOption,
    selectedLeaseCompanyOption,
    questionResultStatusOptions,
    interventionQuestionOptions,
    selectedCustomerOkUserOption
  } = useSnoozedLeadsTableFilterHandlers({ pageSize: PAGE_SIZE });

  if (!snoozedFilters) return;

  const { appointment_date_from, appointment_date_to, snooze_date_from, snooze_date_to, updated_on_from, updated_on_to, interventions } = snoozedFilters;
  const isSnoozeStatusEnabled = user?.dealers?.some(dealer => dealer.id === user.dealer_id && dealer.is_snooze_status_enabled);

  const columnHelper = createColumnHelper<SnoozeItemWithHistory>();

  const columns = [
    columnHelper.accessor((row: SnoozeItem) => row.appointment?.time_car_app, {
      id: "appointment_date",
      header: () => (
        <div className="appointment-table-wrapper default-cursor">
          <span className="no-wrap-text table-header-text">{_startCase(t("v8_appointment_date").message || "Appointment Date")} </span>
          <TableDatePicker
            clearable
            dateStart={appointment_date_from}
            dateEnd={appointment_date_to}
            onChange={handleAppointmentDateRangeChange}
            inputClassName="appointment-date-snoozed-input"
          />
        </div>
      ),
      size: 50,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getFormattedDate(renderValue())}</span>
    }),

    columnHelper.accessor((row: SnoozeItem) => row.appointment, {
      id: "wo",
      header: () => <span className="no-wrap-text table-header-text">WO #</span>,
      size: 50,
      cell: ({ getValue }) => {
        const appointment = getValue();
        if (appointment?.wo_nr === "0") return "";

        return (
          <p className="ellipsis ">
            <Link to={`${PATHS.APPOINTMENTS}/${appointment?.id}`}>{appointment?.wo_nr}</Link>
          </p>
        );
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row.appointment, {
      id: "reg_number",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="ellipsis ellipsis-wo-nr table-header-text"> {t("v8_reg_number").message || "Reg #"}</span>
          <TableDropdownSelector
            options={leaseCompanyOptions}
            multiple={false}
            withSearch={false}
            selected={[selectedLeaseCompanyOption]}
            handleOnSelect={handleOnLeaseSelect}
          />
        </div>
      ),
      size: 50,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (appointment?.reg_number === "0") return "";

        return (
          <p className="ellipsis ellipsis-wo-nr">
            <Link to={`${PATHS.CAR_DETAIL}/${appointment?.car_id}`}>
              {appointment?.reg_number}
              {appointment?.is_lease_company && <Icon name="building" />}
            </Link>
          </p>
        );
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row.dealer_location_id, {
      id: "location_name",
      header: () => <span className="no-wrap-text table-header-text">{t("v8_location").message || "Location"}</span>,
      size: 50,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getLocationName(renderValue(), locations)}</span>
    }),

    columnHelper.accessor((row: SnoozeItem) => row.snooze_date, {
      id: "snooze_date",
      header: () => (
        <div className="appointment-table-wrapper default-cursor" onClick={e => e.stopPropagation()}>
          <span className="no-wrap-text table-header-text">{_startCase(t("v8_snooze_date").message || "Snooze Date")} </span>
          <TableDatePicker
            clearable
            dateStart={snooze_date_from}
            dateEnd={snooze_date_to}
            onChange={handleSnoozedDateRangeChange}
            inputClassName="snooze-date-snoozed-input"
          />
        </div>
      ),
      size: 80,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getFormattedDate(renderValue()?.toString())}</span>
    }),

    columnHelper.accessor((row: SnoozeItem) => row.snooze_status_id, {
      id: "snooze_status",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="ellipsis ellipsis-wo-nr table-header-text no-wrap-text"> {_startCase(t("v8_snooze_status").message || "Snooze Status")}</span>
          {isSnoozeStatusEnabled && (
            <TableDropdownSelector options={snoozeStatusOptions} selected={[selectedSnoozeStatusOption]} handleOnSelect={handleOnSnoozeStatusSelect} />
          )}
        </div>
      ),

      size: 80,
      cell: ({ renderValue }) => {
        const status = snoozeStatusOptions.find(option => option.key === renderValue());
        return <span className="ellipsis ellipsis-wo-nr">{status?.text || ""}</span>;
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row.snooze_type_id, {
      id: "task",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="ellipsis ellipsis-wo-nr table-header-text no-wrap-text"> {t("v8_task").message || "Task"}</span>
          <TableDropdownSelector options={dealerSnoozeTypeOptions} selected={[selectedTaskOption]} handleOnSelect={handleOnTaskSelect} />
        </div>
      ),
      size: 80,
      cell: ({ renderValue }) => {
        const status = allSnoozeTypeOptions.find(option => option.key === renderValue());
        return <span className="ellipsis ellipsis-wo-nr">{status?.text || ""}</span>;
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row.user, {
      id: "last_updated_by",
      header: () => <span className="no-wrap-text table-header-text no-wrap-text">{_startCase(t("v8_last_updated_by").message || "Last Updated By")}</span>,
      size: 80,
      cell: ({ renderValue }) => {
        const user = renderValue();
        return <span className="ellipsis ellipsis-wo-nr">{user ? `${user.first_name} ${user.last_name}`.trim() : ""}</span>;
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row.updated_on, {
      id: "updated_on",
      header: () => (
        <div className="appointment-table-wrapper default-cursor">
          <span className="no-wrap-text table-header-text">{_startCase(t("v8_updated_on").message || "Updated On")} </span>
          <TableDatePicker
            clearable
            dateStart={updated_on_from}
            dateEnd={updated_on_to}
            onChange={handleUpdatedOnDateRangeChange}
            inputClassName="updated-on-snoozed-input"
          />
        </div>
      ),
      size: 80,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getFormattedDate(renderValue())}</span>
    }),

    columnHelper.accessor((row: SnoozeItem) => row.appointment, {
      id: "customer_ok_user",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="table-header-text no-wrap-text"> {t("v8_customer_ok_user").message || "Customer OK User"}</span>
          <TableDropdownSelector selected={[selectedCustomerOkUserOption]} options={customerOkUserOptions} handleOnSelect={handleOnCustomerOkUserSelect} />
        </div>
      ),

      size: 50,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        return (
          <span className="ellipsis ellipsis-wo-nr">
            {appointment?.customer_ok_user ? `${appointment.customer_ok_user.first_name} ${appointment.customer_ok_user.last_name}`.trim() : ""}
          </span>
        );
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row, {
      id: "intervention_question_result",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="table-header-text no-wrap-text"> {t("v8_intervention_question_result").message || "Intervention/Question Result"}</span>
          <TableDropdownSelector
            multiple={true}
            withSearch={true}
            selected={selectedQuestions}
            options={interventionQuestionOptions}
            handleOnSelect={handleOnQuestionSelect}
          />
        </div>
      ),
      size: 80,
      cell: ({ getValue }) => {
        const { question_result, intervention } = getValue();
        return <span>{question_result ? question_result.title : intervention.title}</span>;
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row, {
      id: "note",
      header: () => <span className="table-header-text no-wrap-text"> {t("v8_note").message || "Note"} </span>,
      size: 100,
      cell: ({ getValue }) => {
        const { question_result, intervention, note } = getValue();
        const content = question_result?.mechanic_notes || intervention?.mechanic_notes || note || "";
        return (
          <Popup
            hoverable
            content={content}
            trigger={
              <p className="ellipsis ellipsis-model" style={{ width: "50px" }}>
                {content}
              </p>
            }
          />
        );
      }
    }),

    columnHelper.accessor((row: SnoozeItemWithHistory) => row, {
      id: "status",
      header: () =>
        !interventions && (
          <div className="table-action-buttons-container">
            <TableDropdownSelector
              multiple={true}
              selected={selectedQuestionStatuses}
              options={questionResultStatusOptions}
              handleOnSelect={handleQuestionResultStatusSelect}
              trigger={<TableQuestionStatusButton selectedQuestionStatuses={selectedQuestionStatuses} />}
              scrolling={false}
            />
            <div className="table-icon-placeholder-div" />
            <div className="table-icon-placeholder-div" />
          </div>
        ),
      size: 30,
      cell: ({ getValue }) => {
        const { appointment, question_result, question_result_id, snooze_type_id, snooze_status_id, snooze_department_id, snooze_date, note, appointment_id, history } =
          getValue();

        const snoozeModalData: QuestionResultData = {
          modalType: MODAL_TYPE.EditSnooze,
          title: question_result?.title || "",
          description: question_result?.raw || "",
          solution: question_result?.solution || "",
          question_result_id: question_result_id,
          id: question_result_id,
          images: question_result?.images || [],
          appointment_id: appointment_id,
          snoozed: question_result?.snoozed,
          snooze_history: history,
          snooze_type_id: snooze_type_id,
          snooze_status_id: snooze_status_id,
          snooze_department_id: snooze_department_id,
          snooze_date: snooze_date,
          note,
          checkin_remark_id: question_result?.checkin_remark_id || undefined
        };

        return (
          <div className="table-action-buttons-container">
            <MediaPreview images={question_result?.images} videos={question_result?.videos} />
            {!interventions && <QuestionStatusIcon status={question_result?.status || null} />}
            {appointment?.wo_nr !== "0" ? (
              <CustomerCard
                contractor={appointment?.customer_contract || null}
                owner={appointment?.customer_owner || null}
                driver={appointment?.customer_driver || null}
              />
            ) : (
              <div className="table-icon-placeholder-div" />
            )}
            <SnoozeButtonIcon isSnoozed={true} handleClick={() => setModalData(snoozeModalData)} />
          </div>
        );
      }
    })
  ];

  return (
    <div className="table-container">
      <ReactTable
        columns={columns}
        data={snoozedData || []}
        pageCount={nbPages}
        pageSize={PAGE_SIZE}
        totalItems={nbItems}
        pagination={nbPages > 1 ? { pageIndex: currentPage - 1, pageSize: PAGE_SIZE } : undefined}
        onPaginationChange={handlePaginationSelect}
        overrideTableBodyMessage={
          areAllDateFiltersReset ? `${t("v8_please_select_at_least_one_date_range_to_filter_on").message || "Please select at least one date range to filter on"}.` : null
        }
      />
    </div>
  );
};
