import { NumericFormat } from "react-number-format";
import { Input } from "semantic-ui-react";

export const PriceInput = ({ ...props }) => {
  return (
    <NumericFormat
      customInput={Input}
      thousandSeparator="."
      decimalScale={2}
      allowedDecimalSeparators={[",", "."]}
      decimalSeparator=","
      fixedDecimalScale
      allowNegative={false}
      {...props}
    />
  );
};
