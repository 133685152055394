import React from "react";
import { Controller, ControllerRenderProps, FieldValues, Path, useFormContext } from "react-hook-form";
import { FormField, FormFieldProps, Input, InputOnChangeData, InputProps } from "semantic-ui-react";

import { PriceInput } from "components";

type TypeFormInput<T extends FieldValues> = {
  name: Path<T>;
  inputProps?: InputProps;
  formFieldProps?: FormFieldProps;
  isPriceInput?: boolean;
};

export const FormInput = <T extends FieldValues>({ name, inputProps, formFieldProps, isPriceInput }: TypeFormInput<T>) => {
  const methods = useFormContext<T>();
  const handleOnChange = (_: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData, field: ControllerRenderProps<T, Path<T>>) => {
    if (inputProps?.onChange) return inputProps.onChange(_, { value });
    field.onChange(inputProps?.type === "number" ? parseFloat(value) : value);
  };

  return (
    <Controller
      name={name}
      {...methods}
      render={({ field, fieldState: { invalid, isTouched } }) => (
        <FormField {...formFieldProps} error={invalid && isTouched}>
          {!isPriceInput && <Input {...methods.register(name)} {...field} {...inputProps} onChange={(_, data) => handleOnChange(_, data, field)} />}
          {isPriceInput && (
            <PriceInput
              {...methods.register(name)}
              {...field}
              {...inputProps}
              onChange={(_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => handleOnChange(_, data, field)}
            />
          )}
        </FormField>
      )}
    />
  );
};
