import ENV from "config/Env";
import ApiInstance from "util/Api";

const setZohoDeskASAP = (): void => {
  (window as any).ZohoDeskAsapReady = function (s: () => void) {
    (window as any).ZohoDeskAsap__asyncalls = (window as any).ZohoDeskAsap__asyncalls || [];
    if ((window as any).ZohoDeskAsapReadyStatus) {
      if (s) (window as any).ZohoDeskAsap__asyncalls.push(s);
      (window as any).ZohoDeskAsap__asyncalls.forEach((s: () => void) => s());
      (window as any).ZohoDeskAsap__asyncalls = null;
    } else if (s) (window as any).ZohoDeskAsap__asyncalls.push(s);
  };

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = ENV.zohoASAP;
  script.defer = true;

  document.getElementsByTagName("head")[0].appendChild(script);
};

const login = () => {
  if (ApiInstance.zohoAsapJWT && (window as any).ZohoDeskAsapReady) {
    (window as any).ZohoDeskAsapReady(() => {
      (window as any).ZohoDeskAsap.invoke("login", (sucessCallback: (token: string) => void, _: () => void) => {
        sucessCallback(ApiInstance.zohoAsapJWT);
        (window as any).ZohoDeskAsap.set("app.lang", localStorage.getItem("locale")?.replace("-", "_") || "en_GB");
      });
    });
  }
};

const logout = () => {
  if ((window as any).ZohoDeskAsapReady) {
    (window as any).ZohoDeskAsapReady(() => (window as any).ZohoDeskAsap.invoke("logout"));
    const ZohohcAsapWebButton = document.getElementById("zohohc-asap-web-button");
    if (ZohohcAsapWebButton?.style.left === "auto") {
      ZohohcAsapWebButton.click();
      ZohohcAsapWebButton.style.left = "-100px";
    }
  }
};

export default { setZohoDeskASAP, login, logout };
