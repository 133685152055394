import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Tab } from "semantic-ui-react";

import { AttachmentPreview, CUSTOM_CONFIRM_TYPES, CustomConfirm, DATE_FORMATS_FNS, FileDragDrop } from "components";
import { Intervention } from "models";
import "modules/AppointmentDetails/components/Interventions/components/Attachments/Attachments.scss";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { getNameFromURL } from "util/common";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

export type GenericAttachmentData = {
  id?: number;
  url: string;
  name: string;
  created_on?: string;
  updated_on?: string;
  type?: string;
};

type AttachmentsProps<T extends GenericAttachmentData> = {
  intervention: Intervention;
  attachments?: T[];
};

const handleOpenAttachment = (url: string) => {
  window.open(url, "_blank");
};

export const Attachments = <T extends GenericAttachmentData>({ intervention, attachments }: AttachmentsProps<T>) => {
  const t = useTranslation().t as ITranslation;
  const [selectedDeleteAttachmentID, setSelectedDeleteAttachmentID] = useState<number>(0);
  const { uploadAttachment, addAttachmentToInterventionMutation, deleteInterventionAttachmentMutation } = useIntervention(intervention.appointment_id);

  const handleUploadAttachment = async (file: File) => {
    if (!file) return;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });

    const attachmentUrl = await uploadAttachment(intervention.appointment_id, uploadFile);

    if (attachmentUrl) {
      addAttachmentToInterventionMutation.mutate({
        url: attachmentUrl,
        intervention_id: intervention.id,
        type: file.type,
        name: getNameFromURL(attachmentUrl)
      });
    }
  };

  const handleFileUpload = (files: FileList | null) => {
    if (!files) return;

    [...files].forEach(file => {
      handleUploadAttachment(file);
    });
  };

  const onDeleteAttachment = (intervention_attachment_id: number) => {
    setSelectedDeleteAttachmentID(intervention_attachment_id);
  };

  const handleCancelDeleteAttachment = () => {
    setSelectedDeleteAttachmentID(0);
  };

  const handleConfirmDeleteAttachment = () => {
    if (selectedDeleteAttachmentID) {
      deleteInterventionAttachmentMutation.mutate({ intervention_attachment_id: selectedDeleteAttachmentID, intervention_id: intervention.id });
    }

    setSelectedDeleteAttachmentID(0);
  };

  const renderListView = () => {
    return (
      !!attachments?.length && (
        <div className="attachment-container">
          {attachments.map((item: GenericAttachmentData) => {
            return (
              <div key={item.id} className="attachment-view">
                <div className="attachment-name" onClick={() => handleOpenAttachment(item.url)}>
                  <Icon className="image" />
                  <p className="ellipsis">{item.name || (item.created_on && formatDate(item.created_on, DATE_FORMATS_FNS.dateMonthYear))}</p>
                </div>
                <div className="attachment-delete">
                  {item.created_on && <p>{`${t("v8_uploaded_on").message || "Uploaded on"} ${formatDate(item.created_on, DATE_FORMATS_FNS.dateMonthYearTime)}`}</p>}
                  {item.id && item.name && <Icon className="trash grey" onClick={() => onDeleteAttachment(Number(item.id))} />}
                </div>
              </div>
            );
          })}
        </div>
      )
    );
  };

  const renderGalleryView = () => {
    return (
      !!attachments?.length && (
        <div className="attachment-container">
          <AttachmentPreview attachments={attachments} onDeleteRequest={item => onDeleteAttachment(Number(item.id))} canDelete={true} />
        </div>
      )
    );
  };

  return (
    <div className="Attachments">
      <Tab
        style={{ padding: "0" }}
        menu={{ attached: false, tabular: false }}
        panes={[
          {
            menuItem: { key: "list", icon: "list", content: t("v8_list").message || "List" },
            render: () => (
              <>
                <FileDragDrop id="intervention-image" onFileUpload={handleFileUpload} />
                <Tab.Pane>{renderListView()}</Tab.Pane>
              </>
            )
          },
          {
            menuItem: { key: "gallery", icon: "photo film", content: t("v8_gallery").message || "Gallery" },
            render: () => (
              <>
                <FileDragDrop id="intervention-image" onFileUpload={handleFileUpload} />
                <Tab.Pane>{renderGalleryView()}</Tab.Pane>
              </>
            )
          }
        ]}
        grid={{ paneWidth: 16, tabWidth: 8 }}
      />

      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        handleCancel={handleCancelDeleteAttachment}
        isOpen={!!selectedDeleteAttachmentID}
        isLoading={deleteInterventionAttachmentMutation.isPending}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        handleConfirm={handleConfirmDeleteAttachment}
      />
    </div>
  );
};
